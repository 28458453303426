import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { img } from "gatsby-plugin-image"
import { DefaultHeader } from "components/headers/default-header"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPlus, faEquals } from "@fortawesome/free-solid-svg-icons"

const StoreCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="デジタル × リアルイベント支援 | らくらくQRコードキャンペーン"
          pagedesc="最先端の商業施設へ！デジタルキャンペーン初心者にもお勧めのQRコードキャンペーン"
          pagepath={location.pathname}
        />
        <main className="store font-noto">
          <section className="store__fv">
            <div className="md:pt-6 pt-14 pb-20 md:px-24 px-2">
              <div className="text-center w-1/2 md:w-full mx-auto">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/top-subtitle.png"
                  alt="最先端の商業施設へ"
                  layout="constrained"
                />
              </div>
              <div className="w-10/12 mx-auto text-center md:block hidden md:mb-2">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/top_text_title.png"
                  alt="デジタル × リアルイベント支援 らくらくQRコードキャンペーン"
                  layout="constrained"
                />
              </div>
              <div className="text-center md:hidden block mx-auto mb-1">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/top_text_title_sp.png"
                  alt="デジタル × リアルイベント支援 らくらくQRコードキャンペーン"
                  layout="constrained"
                />
              </div>
              <div className="md:flex hidden md:gap-10 md:mb-6 circle-container mx-auto">
                <div>
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/feat_01.png"
                    alt="デジタルキャンペーン初心者にもお勧め"
                    layout="constrained"
                  />
                </div>
                <div>
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/feat_02.png"
                    alt="最低価格13万円〜"
                    layout="constrained"
                  />
                </div>
                <div>
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/feat_03.png"
                    alt="企画〜印刷を一貫して発注可能!!"
                    layout="constrained"
                  />
                </div>
              </div>
              <div className="block md:hidden text-center mb-6">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/sp_feat.png"
                  alt="デジタルキャンペーン初心者にもお勧め・最低価格13万円〜・企画〜印刷を一貫して発注可能"
                  layout="constrained"
                />
              </div>
              <div className="text-center">
                <a
                  href="https://hub.park-lot.com/document-download-qrcode-campaign"
                  target="_blank"
                  rel="noreferrer"
                  className="store__fv-cta-btn text-white inline-block text-xl md:text-4xl font-bold px-20 py-4 md:py-5"
                >
                  資料ダウンロード
                </a>
              </div>
            </div>
          </section>
          <section className="store__merit">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-20 mb-8">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/title_merit.png"
                  alt="購入促進・告知に活躍!!"
                  layout="constrained"
                />
              </h2>
              <div className="bg-white px-4 py-6 md:py-12 md:px-20">
                <div className="md:flex md:gap-20 items-center">
                  <div className="md:w-2/6 w-1/2 mx-auto mb-6 md:mb-0">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/phone.png"
                      alt=""
                      layout="constrained"
                    />
                  </div>
                  <div className="md:flex-1">
                    <div className="md:mb-14 mb-6">
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        店舗への送客
                      </h3>
                      <p className="md:text-xl font-bold">
                        実際に店舗に来なければ参加できないキャンペーンを開催し、集客力をアップさせましょう！
                      </p>
                    </div>
                    <div className="md:mb-14 mb-6">
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        コードを管理
                      </h3>
                      <p className="md:text-xl font-bold">
                        PARKLoTのQRコードキャンペーンは、QRコードもバリアブルで発行・管理することが可能です。
                      </p>
                    </div>
                    <div>
                      <h3 className="font-bold md:text-4xl text-2xl mb-2">
                        チラシに埋め込める
                      </h3>
                      <p className="md:text-xl font-bold">
                        QRコードはチラシに埋め込むことが可能です。アプリダウンロードやSNSでフォローなどの過程がなくても、スマホ一つでキャンペーンに参加していただけます。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="store__custom md:py-32 py-20">
            <div className="container px-4 md:px-0">
              <h2 className="text-center md:mb-20 mb-8">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/title_custom.png"
                  alt="細かなカスタマイズも充実"
                  layout="constrained"
                />
              </h2>
              <ul className="flex w-full flex-wrap mx-auto md:mb-20 mb-8 text-center justify-center">
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    バリアブルな
                    <br className="md:block hidden" />
                    QRコードを発行・印刷
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_print.png"
                      alt="バリアブルなQRコードを発行・印刷"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    1枚ごとに異なるQRコードを印字可能
                    <br />
                    QRコード発行から印刷まで
                    <br className="hidden md:block" />
                    一貫して受注
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    QRコード発行
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_qr.png"
                      alt="QRコード発行"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    シリアルコードよりも手軽に
                    <br className="hidden md:block" />
                    スマホで参加可能
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    自社のLP内で
                    <br />
                    抽選が可能
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_lp.png"
                      alt="自社のLP内で抽選が可能"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    キャンペーンのLPサイト内でも
                    <br className="hidden md:block" />
                    抽選可能
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    重複抽選の防止
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_prevention.png"
                      alt="重複抽選の防止"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    一度抽選が行われた
                    <br className="hidden md:block" />
                    QRコードは無効にできる
                  </p>
                </li>
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    演出の設定も自由
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_lottery.png"
                      alt="演出の設定も自由"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    抽選時の演出に使用する映像を
                    <br className="hidden md:block" />
                    自由に設定可能
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    自動抽選機能
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_automatic.png"
                      alt="自動抽選機能"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    当選者を自動で抽選
                    <br className="hidden md:block" />
                    後日抽選や即時抽選の選択も可能
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    DM送信機能
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_dm.png"
                      alt="DM送信機能"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    当選結果通知として
                    <br className="hidden md:block" />
                    ユニークなギフトコード、URLを送信
                  </p>
                </li>
                <li className="item item--yellow">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    並び順をカスタマイズ
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_sort.png"
                      alt="並び順をカスタマイズ"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    投稿日時やいいねの獲得数など、
                    <br className="hidden md:block" />
                    並び順をカスタマイズ
                  </p>
                </li>
                <li className="item item--pink">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    抽選画面の
                    <br />
                    デザイン
                    <br className="md:hidden block" />
                    カスタマイズ
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_design.png"
                      alt="抽選画面のデザインカスタマイズ"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    自社サイトやキャンペーンサイトに
                    <br className="hidden md:block" />
                    合ったデザインの提供
                  </p>
                </li>
                <li className="item item--blue">
                  <h3 className="font-bold md:text-2xl mb-4 md:mb-2">
                    LINEの
                    <br className="hidden md:block" />
                    友達登録から参加
                  </h3>
                  <div className="mb-4 md:mb-2">
                    <img
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/ico_design.png"
                      alt="LINEの友達登録から参加"
                      layout="constrained"
                    />
                  </div>
                  <p className="text-sm md:text-xl">
                    LINEの友達登録でQRコードを
                    <br className="hidden md:block" />
                    送信し、キャンペーンに参加
                  </p>
                </li>
              </ul>
              <div className="text-center md:mb-10 mb-8">
                <a
                  href="https://hub.park-lot.com/document-download-qrcode-campaign"
                  target="_blank"
                  rel="noreferrer"
                  className="store__custom-cta-btn inline-block rounded-xl md:py-6 py-4 md:px-24 w-full md:w-auto bg-white font-bold text-xl md:text-4xl"
                >
                  資料ダウンロード
                </a>
              </div>
              {/* <p className="md:text-xl font-bold text-center">
                金額は「料金プラン」をご覧ください
              </p> */}
            </div>
          </section>
          <section className="store__howto">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-24 mb-12">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/title_howto.png"
                  alt="こんな使い方ができます"
                  layout="constrained"
                />
              </h2>
              <div className="case-container md:mb-28 mb-12 md:w-4/5">
                <div className="case-title">
                  <h3 className="z-10 text-xl md:text-3xl text-white inline-block text-center">
                    case1
                  </h3>
                </div>
                <div className="case-img mb-6 md:mb-0">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/img_case01.png"
                    alt="チラシだけで参加できるキャンペーンの開催"
                    layout="constrained"
                  />
                </div>
                <h4 className="font-bold text-2xl md:text-4xl md:mb-6 mb-2 pt-3">
                  チラシだけで参加できる
                  <br />
                  キャンペーンの開催
                </h4>
                <p className="md:text-xl">
                  1.店舗でシリアルコード付きのクジを配布
                  <br />
                  2.QRコードをスマートフォンで読み込み
                  <br />
                  3.サイトへ移動し抽選!!
                </p>
              </div>
              <div className="case-container md:w-4/5">
                <div className="case-title">
                  <h3 className="z-10 text-xl md:text-3xl text-white inline-block text-center">
                    case2
                  </h3>
                </div>
                <div className="case-img mb-6 md:mb-0">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/img_case02.png"
                    alt="好きなときに抽選できる"
                    layout="constrained"
                  />
                </div>
                <h4 className="font-bold text-2xl md:text-4xl md:mb-6 mb-2 pt-3">
                  好きなときに抽選できる
                </h4>
                <p className="md:text-xl">
                  並ばなくても抽選に参加可能!!
                  <br />
                  カフェタイムや帰り道で抽選にチャレンジ
                </p>
              </div>
            </div>
          </section>
          {/* <section className="store__plan">
            <div className="container md:py-32 py-20 md:px-0 px-4">
              <h2 className="text-center md:mb-24 mb-12">
                <img
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/store/title_plan.png"
                  alt="料金プラン"
                  layout="constrained"
                />
              </h2>
              <div className="bg-white md:py-12 md:px-14 py-6 px-3 mb-10">
                <h3 className="md:text-3xl text-2xl font-bold text-center mb-8">
                  基本費用
                </h3>
                <div className="flex items-center justify-center md:gap-6 gap-2 mb-4 md:mb-5">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">初期費用</p>
                    <p className="font-bold text-xl md:text-4xl">8万円</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-2xl md:text-5xl text-text"
                  />
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">月額</p>
                    <p className="font-bold text-xl md:text-4xl">5万円</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faEquals}
                    className="text-2xl md:text-5xl text-text"
                  />
                  <div className="price-box flex-1 price-box--emphasis">
                    <p className="text-sm mb-2 md:text-2xl">合計</p>
                    <p className="font-bold text-xl md:text-4xl">13万円</p>
                  </div>
                </div>
                <p className="md:text-xl text-sm">
                  最低20,000枚から可能
                  <br />
                  ※枚数によって金額変動します
                  <br />
                  ※印刷代：90,000円〜(印刷代の表記方法は検討)
                  <br />
                  ※納期は1週間くらい
                </p>
              </div>
              <div className="bg-white md:py-12 md:px-14 py-6 px-3 md:mb-20 mb-8">
                <h3 className="md:text-3xl text-2xl font-bold text-center mb-8">
                  オプション
                </h3>
                <div className="flex flex-wrap md:gap-10 gap-2 justify-center mb-2 md:mb-10">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">いいね収集</p>
                    <p className="font-bold text-xl md:text-4xl">3万円</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">リプライ収集</p>
                    <p className="font-bold text-xl md:text-4xl">3万円</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      デザイン
                      <br />
                      カスタマイズ
                    </p>
                    <p className="font-bold text-xl md:text-4xl">3万円</p>
                  </div>
                </div>
                <div className="flex flex-wrap md:gap-10 gap-2 justify-center">
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      並び順
                      <br />
                      カスタマイズ
                    </p>
                    <p className="font-bold text-xl md:text-4xl">3万円</p>
                  </div>
                  <div className="price-box flex-1">
                    <p className="text-sm mb-2 md:text-2xl">
                      キャンペーンLP
                      <br />
                      作成代行
                    </p>
                    <p className="font-bold text-xl md:text-4xl">10万円〜</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                  className="store__custom-cta-btn inline-block rounded-xl md:py-6 py-4 md:px-24 w-full md:w-auto bg-white font-bold text-xl md:text-4xl"
                >
                  資料ダウンロード
                </a>
              </div>
            </div>
          </section> */}
        </main>
      </Layout>
    </>
  )
}
export default StoreCampaign
